<template>
    <div class="wit-password__wrapper">
        <div :class="{'wit-password': true, 'wit-password--toggled': showPassword}">
            <b-form-input
                v-model="password"
                :type="showPassword ? 'text' : 'password'"
                ref="password"
                :placeholder="placeholder"
                @input="emitInput"
                :id="id"
                :disabled="disabled"
            />
            <div class="wit-password__toggle" @click="() => (showPassword = !showPassword)">
                <InlineSvg :src="require('@/assets/img/password.svg')" />
            </div>
        </div>
        <p class="wit-password__reset" v-if="showReset">
            <router-link to="/auth/forgot-password">Forgot your password?</router-link>
        </p>
        <div class="wit-password__validations" v-if="validationMessages && model">
            <ul>
                <li
                    v-for="key in Object.keys(validationMessages)"
                    :key="key"
                    :class="{'wit-password__validation': true, 'wit-password__validation--valid': model[key]}"
                >
                    <InlineSvg :src="require('@/assets/img/tick.svg')" />
                    <span>{{ validationMessages[key] }}</span>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg'

export default {
    components: {
        InlineSvg,
    },
    props: {
        value: {
            type: String,
            required: true,
        },
        placeholder: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
        },
        id: {
            type: String,
        },
        showReset: {
            type: Boolean,
            default: false,
        },
        validationMessages: {
            type: Object,
        },
        model: {
            type: Object,
        },
    },
    watch: {
        value() {
            this.password = this.value
        },
    },
    created() {
        if (this.value) {
            this.password = this.value
        }
    },
    data() {
        return {
            password: '',
            showPassword: false,
        }
    },
    methods: {
        emitInput() {
            this.$emit('input', this.password)
        },
    },
}
</script>

<style lang="scss">
.wit-password {
    $self: &;

    position: relative;

    &__wrapper:focus-within {
        #{ $self }__validations {
            display: block;
            visibility: visible;
        }
    }

    &__toggle {
        position: absolute;
        top: 7px;
        right: 7px;
        cursor: pointer;
        padding: 5px;
    }

    &--toggled {
        #{ $self }__toggle {
            path {
                fill: black;
            }
        }
    }

    &__reset {
        position: absolute;
        top: -30px;
        right: 15px;
        width: 100%;
        text-align: right;
        margin-top: 5px;
        font-size: 12px;

        a,
        a:hover {
            color: #eba900;
            text-decoration: none;
        }
    }

    &__validations {
        width: calc(100% - 30px);
        background: #ffffff;
        border: 1px solid #e6ecef;
        box-shadow: 0px 15px 30px rgba(85, 85, 85, 0.05);
        border-radius: 8px;
        padding: 10px;
        box-sizing: border-box;
        position: absolute;
        z-index: 4000;
        top: 53px;
        display: none;
        visibility: hidden;

        ul {
            padding: 0;
            display: grid;
            grid-template-columns: auto auto;
            list-style: none;
            margin: 0;
        }
    }

    &__validation {
        margin: 5px;

        svg {
            margin-right: 8px;
        }

        span {
            font-family: 'Objectivity';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 12px;
            color: #000000;
        }

        &--valid {
            svg {
                rect:first-child {
                    fill: #0bd281;
                }
                rect:last-child {
                    stroke: #0bd281;
                }
            }
        }
    }
}
</style>
